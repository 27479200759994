var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contents" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form-inline",
          attrs: { model: _vm.form, inline: "" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "出货仓库：", prop: "out_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.out_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "out_id", $$v)
                    },
                    expression: "form.out_id",
                  },
                },
                _vm._l(_vm.cklist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.depot_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "入货仓库：", prop: "in_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.in_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "in_id", $$v)
                    },
                    expression: "form.in_id",
                  },
                },
                _vm._l(_vm.cklist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.depot_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "start_time" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "130px" },
                attrs: {
                  type: "date",
                  placeholder: "选择开始日期",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.form.start_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "start_time", $$v)
                  },
                  expression: "form.start_time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "至", prop: "end_time" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "130px" },
                attrs: {
                  type: "date",
                  placeholder: "选择结束日期",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.form.end_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "end_time", $$v)
                  },
                  expression: "form.end_time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "query_time" } },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { "true-label": "1", "false-label": "0" },
                  model: {
                    value: _vm.form.query_time,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "query_time", $$v)
                    },
                    expression: "form.query_time",
                  },
                },
                [_vm._v(" 查询审核时间 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "allot_code" } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入单号或客户名称" },
                  model: {
                    value: _vm.form.allot_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "allot_code", $$v)
                    },
                    expression: "form.allot_code",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-search",
                    attrs: { slot: "prefix" },
                    slot: "prefix",
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "申请人：", prop: "add_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.add_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "add_id", $$v)
                    },
                    expression: "form.add_id",
                  },
                },
                _vm._l(_vm.yglist, function (item) {
                  return _c("el-option", {
                    key: item.user_id,
                    attrs: { label: item.user_name, value: item.user_id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核人：", prop: "check_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.check_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "check_id", $$v)
                    },
                    expression: "form.check_id",
                  },
                },
                _vm._l(_vm.yglist, function (item) {
                  return _c("el-option", {
                    key: item.user_id,
                    attrs: { label: item.user_name, value: item.user_id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "类型：", prop: "allot_type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.allot_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "allot_type", $$v)
                    },
                    expression: "form.allot_type",
                  },
                },
                _vm._l(_vm.type, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.type_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "结算方式:", prop: "is_wxpay" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { filterable: "", placeholder: "结算方式" },
                  model: {
                    value: _vm.form.is_wxpay,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_wxpay", $$v)
                    },
                    expression: "form.is_wxpay",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "现金", value: "0" } }),
                  _c("el-option", { attrs: { label: "在线支付", value: "1" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态：", prop: "allot_status" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.allot_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "allot_status", $$v)
                    },
                    expression: "form.allot_status",
                  },
                },
                _vm._l(_vm.status, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.status_name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Submit } },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.detory } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
            [
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "end" },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.adddb } },
                    [_vm._v("添 加")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导 出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-docum", { ref: "adddocum", on: { getlist: _vm.getlist } }),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            stripe: "",
            data: _vm.tabledoc,
            border: "",
            height: _vm.tableHeight,
            "tooltip-effect": "dark",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "30" } }),
          _vm._l(_vm.colmit, function (list, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: list.prop,
                label: list.label,
                width: "",
                align: list.align,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        list.label == "调拨单号"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "uderline",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlerchakan(row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(row[list.prop]) + " ")]
                              ),
                              row.order_codes !== "" ? _c("div") : _vm._e(),
                              row.order_codes !== ""
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: { color: "#f60" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlerchakanF60(row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(row.order_codes) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _c("div", [
                              _vm._v(" " + _vm._s(row[list.prop]) + " "),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              fixed: "right",
              width: "230",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              scope.row.allot_status == 0 ||
                              scope.row.allot_status == 1,
                            expression:
                              "scope.row.allot_status == 0 || scope.row.allot_status == 1",
                          },
                        ],
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    scope.row.allot_status == 1 &&
                    _vm.authbutton[4].is_check == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 审核 ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.allot_status != 1,
                            expression: "scope.row.allot_status != 1",
                          },
                        ],
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerchakan(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 查看 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handlePrint(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 打印 ")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          icon: "el-icon-info",
                          title: "确定要作废吗？",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.handleDelete(scope.$index, scope.row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.allot_status == 2,
                                expression: "scope.row.allot_status == 2",
                              },
                            ],
                            staticStyle: { "margin-left": "10px" },
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [_vm._v(" 作废 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.form.pageNo,
              "page-sizes": [10, 15, 20, 50, 100],
              "page-size": 10,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalCount,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("add-goods", { ref: "shenhe", on: { getlist: _vm.getlist } }),
      _c("edit", { ref: "edit", on: { getlist: _vm.getlist } }),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "DB", data_id: _vm.data_id, type: 1 },
      }),
      _c("advanceOrder", { ref: "advanceOrder" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }