<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="选择商品"
      top="5vh"
      :visible.sync="tableInputWindow"
      width="1050px"
      @close="close"
    >
      <el-input
        v-model="form.keyword"
        placeholder="输入关键字进行查询"
        style="width: 250px; margin: 5px 10px 5px 0"
      ></el-input>
      <el-button style="margin-right: 10px" @click="filter">查询</el-button>
      <el-checkbox v-model="form.has_stock" false-label="0" true-label="1">
        只显示有库存商品
      </el-checkbox>
      <el-row :gutter="10">
        <el-col :span="6">
          <div class="tit">商品类别</div>
          <div v-loading="treeLoading" class="grid-content bg-purple">
            <!-- tree -->
            <el-tree
              ref="tree"
              class="filter-tree"
              :data="data"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              style="height: 350px; overflow: auto"
              @node-click="treeClick"
            ></el-tree>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="grid-content bg-purple">
            <!-- 右侧表格 -->
            <el-table
              stripe
              :data="list"
              height="350"
              @selection-change="setSelectRows"
              @cell-click="cellClick"
            >
              <!-- 选择框 -->
              <el-table-column
                align="center"
                type="selection"
                width="60"
              ></el-table-column>
              <!-- 序号 -->
              <el-table-column
                prop=""
                align="center"
                label="序号"
                min-width="50px"
              >
                <template #default="{ $index }">
                  <span>{{ $index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="goods_name"
                align="center"
                label="商品名称"
                min-width="auto"
              ></el-table-column>
              <el-table-column
                prop="goods_code"
                align="center"
                label="小单位条码"
                min-width="120px"
              ></el-table-column>
              <el-table-column
                prop="class_name"
                align="center"
                label="品类"
                min-width="auto"
              ></el-table-column>
              <el-table-column
                prop="brand_name"
                align="center"
                label="品牌"
                min-width="auto"
              ></el-table-column>
              <el-table-column
                prop="balance_count"
                align="center"
                label="可用库存"
                min-width="auto"
              ></el-table-column>
              <el-table-column
                prop="unit_cv"
                align="center"
                label="单位换算"
                min-width="auto"
              ></el-table-column>
              <el-table-column
                prop="is_close_text"
                align="center"
                label="状态"
                min-width="auto"
              ></el-table-column>
            </el-table>
            <el-pagination
              :current-page="form.pageNo"
              :layout="layout"
              :page-size="form.pageSize"
              :total="total"
              background
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">确认</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { goodsSearch, getTableList } from '@/api/advanceOrder'
  export default {
    name: 'TableInputSearchWindow',
    data() {
      return {
        tableInputWindow: false,
        depot_id: 0,
        data: [],
        form: {
          pageNo: 1,
          pageSize: 10,
          has_stock: '0',
          keyword: '',
        },
        treeSelect: {},
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        tableLoading: false,
        treeLoading: true,
        defaultProps: {
          children: 'children',
          label: 'brand_name',
        },
        // 表格
        list: [],
      }
    },
    watch: {
      // filterText(val) {
      //   this.$refs.tree.filter(val)
      // },
      tableInputWindow(val) {
        if (val) {
          this.fetchData()
        } else {
          this.treeLoading = true
        }
      },
    },
    methods: {
      close() {
        this.tableInputWindow = false
        // this.$refs['addRowFrom'].resetFields() // 重置表单
      },
      async fetchData() {
        let { data, code, msg } = await goodsSearch()
        if (code == 200) {
          console.log(data)
          this.data = data.branch
        } else {
          this.$message.error(msg)
        }
        this.treeLoading = false
      },
      treeClick(val) {
        this.tableLoading = true
        this.treeSelect = val
        console.log(this.depot_id)
        // this.form.pageNo = 1
        // this.form.pageSize = 10
        console.log(val)
        // getTableList(this.form).then((res) => {
        //   console.log(res)
        //   if (res.code == 200) {
        //     this.list = res.data
        //     this.total = res.totalCount
        //   } else {
        //     this.$message.error('获取失败，请重试')
        //   }
        //   this.tableLoading = false
        // })
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.treeClick(this.treeSelect)
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.treeClick(this.treeSelect)
      },
      save() {
        this.tableInputWindow = false
      },
      filter() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.$refs.tree.filter(this.form.keyword)
      },
      filterNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },
      setSelectRows() {},
      cellClick() {},
    },
  }
</script>

<style lang="scss" scoped>
  .tit {
    padding: 0 0 10px 0;
    text-align: center;
  }
</style>
