<!--
 * @Author: 候怀烨
 * @Date: 2020-11-26 17:06:50
 * @LastEditTime: 2021-04-27 14:59:30
 * @LastEditors: Please set LastEditors
 * @Description: 添加调拨单
 * @FilePath: \sd-vue-admin\src\views\project\treasury\requisition\documents\components\AddDocum.vue
-->
<template>
  <div>
    <!-- :close-on-click-modal="false" -->
    <el-dialog
      :modal="false"
      title="添加调拨单"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
      width="1100px"
      fullscreen
    >
      <div>
        <el-form
          ref="form"
          :model="form"
          :inline="true"
          class="demo-form-inline"
          :rules="rules"
        >
          <el-form-item label="出货仓库：" prop="out_id">
            <el-select
              v-model="form.out_id"
              filterable
              placeholder="请选择"
              style="width: 130px"
            >
              <el-option
                v-for="item in cklist"
                :key="item.id"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入货仓库：" prop="in_id">
            <el-select
              v-model="form.in_id"
              filterable
              placeholder="请选择"
              style="width: 130px"
            >
              <el-option
                v-for="item in cklist"
                :key="item.id"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="申请人：" prop="add_id">
            <el-select
              v-model="form.add_id"
              filterable
              placeholder="请选择"
              style="width: 130px"
            >
              <el-option
                v-for="item in yglist"
                :key="item.user_id"
                :label="item.user_name"
                :value="item.user_id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="类型：" prop="allot_type">
            <el-select
              v-model="form.allot_type"
              filterable
              placeholder="请选择"
              style="width: 130px"
            >
              <el-option
                v-for="item in type"
                :key="item.id"
                :label="item.type_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="" prop="">
            <el-button v-if="showBtn" type="primary" @click="handleLoadCar">
              加载出货库车存
            </el-button>
          </el-form-item>
          <el-form-item label="" prop="">
            <span style="color: red">
              提示:如使用调拨价方案，添加、编辑调拨单时请不要修改车库
            </span>
          </el-form-item>
        </el-form>
        <!-- 表格 -->
        <!-- show-summary -->
        <el-table
          ref="tableSort"
          stripe
          :data="list"
          border
          show-summary
          :summary-method="getSummaries"
          @selection-change="setSelectRows"
        >
          <!-- 选择框 -->
          <el-table-column
            align="center"
            type="selection"
            width="90"
          ></el-table-column>
          <!-- 序号 -->
          <el-table-column prop="" align="center" label="序号" min-width="50px">
            <template #default="{ $index }">
              <span>{{ $index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" align="center" label="类型" width="110">
            <template #default="{ row }">
              <el-select
                v-model="row.goods_type"
                clearable
                style="width: 100%"
                placeholder="类型"
              >
                <el-option
                  v-for="(i, idx) in goodsTypeList"
                  :key="idx"
                  :value="i.id"
                  :label="i.name"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- 商品名称 -->
          <el-table-column
            prop="goods_name"
            align="center"
            label="商品名称"
            min-width="150px"
          >
            <template #default="{ $index, row }">
              <template v-if="edit">
                <goods-search
                  ref="goodsSearch"
                  :f-key="row.goods_name"
                  :depot-id="form.out_id"
                  :dbj="true"
                  :disab="goodsDisable"
                  :goods-list-obj="{
                    allot_type: form.allot_type,
                    depot_id2: form.in_id,
                  }"
                  :kouwei="row.child"
                  :rowf="row"
                  @add-rows="addRows"
                  @select-goods-all="handleSelect($index, row, $event)"
                  @change-kouwei="changeKouwei($event, row)"
                ></goods-search>
                <!-- <el-autocomplete
                  v-model="row.goods_name"
                  class="inline-input"
                  popper-class="selectDC"
                  :fetch-suggestions="querySearch2"
                  value-key="goods_name"
                  placeholder="请输入商品名称、简拼"
                  :brand-id="form.out_id"
                  @select="handleSelect($index, row, $event)"
                  @input="addRow($index, row, $event)"
                >
                  <i
                    slot="suffix"
                    class="el-input__icon el-icon-search"
                    @click="iconClick($index, row)"
                  ></i>
                  <template slot-scope="{ item }">
                    <div class="wrap" style="display: flex">
                      <div
                        class="name"
                        style="width: 200px; margin-right: 120px"
                      >
                        {{ item.goods_name }}
                      </div>
                      <span class="addr">{{ item.specs }}</span>
                    </div>
                  </template>
                </el-autocomplete> -->
              </template>
              <span v-else>{{ row.goods_name }}</span>
            </template>
          </el-table-column>
          <!-- 单位 -->
          <el-table-column
            prop="type"
            align="center"
            label="单位"
            width="140px"
          >
            <template #default="{ row }">
              <template v-if="edit">
                <!-- <el-input v-model="row.type" style="width: 100px" /> -->
                <el-select
                  v-model="row.unit_id"
                  placeholder="选择单位"
                  style="width: 110px"
                  @change="handlerselect(row.unit_id, row.arr_unit, row)"
                >
                  <el-option
                    v-for="item in row.arr_unit"
                    :key="item.id"
                    :label="item.unit_name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
              <span v-else>{{ row.units }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="specs"
            align="center"
            label="规格"
          ></el-table-column>
          <!-- 订购数量 -->
          <el-table-column
            prop="quantity"
            align="center"
            label="调拔数量"
            min-width="110px"
          >
            <template #default="{ $index, row }">
              <span v-if="edit">
                <el-input
                  v-model="row.quantity"
                  style="width: 80px"
                  @input="moneyN(row, $index)"
                  @focus="inputfocus(row)"
                />
              </span>
              <span v-else>{{ row.quantity }}</span>
            </template>
          </el-table-column>
          <!-- 单价 -->
          <el-table-column
            prop="goods_price"
            align="center"
            label="单价"
            min-width="110px"
          >
            <template #default="{ $index, row }">
              <template v-if="edit">
                <el-input
                  v-model="row.goods_price"
                  style="width: 80px"
                  @input="moneyN(row, $index)"
                />
              </template>
              <span v-else>{{ row.goods_price }}</span>
            </template>
          </el-table-column>
          <!-- 金额 -->
          <el-table-column
            prop="goods_money"
            align="center"
            label="金额"
            min-width="90px"
          >
            <template #default="{ row }">
              <div v-if="row.goods_type == 1">
                {{ (row.goods_price * Number(row.quantity)).toFixed(2) }}
              </div>
              <div v-else>0</div>
            </template>
          </el-table-column>
          <!-- 可用库存 -->
          <el-table-column
            prop="depot_stock_num_read"
            align="center"
            label="可用库存"
            min-width="110px"
          >
            <template #default="{ row }">
              <!-- {{ row.depot_stock_num }} -->
              {{ row.depot_stock_num_read }}
            </template>
          </el-table-column>
          <!-- 实际库存 -->
          <el-table-column
            prop="balance_count"
            align="center"
            label="实际库存"
            min-width="110px"
          >
            <template #default="{ row }">
              {{ row.balance_count_read }}
            </template>
          </el-table-column>

          <!-- 生产日期 -->
          <!-- <el-table-column
            prop="goods_production_date"
            align="center"
            label="生产日期"
            min-width="110px"
          >
            <template #default="{ row }">
              {{ row.goods_production_date }}
            </template>
          </el-table-column>
          <el-table-column
            prop="info"
            align="center"
            label="备注"
            min-width="110px"
          >
            <template #default="{ row }">
              {{ row.info }}
            </template>
          </el-table-column> -->

          <el-table-column
            align="center"
            label="操作"
            fixed="right"
            width="160"
          >
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="copyRow($index, row)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="deleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row class="texts">
          <span>备注：</span>
          <el-input
            v-model="form.remark"
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            style="width: 400px"
          ></el-input>
        </el-row>
        <span>制单人：{{ username }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlerbaocun">添加</el-button>
        <!-- <el-button @click="dialogVisible = false">保 存</el-button> -->
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <table-input-window ref="tableInputWindow"></table-input-window>
  </div>
</template>

<script>
  import TableInputWindow from '../../../../sale/bills/advanceOrder/components/orderTableEdit/components/tableInputSearhWindow'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { getInputList } from '@/api/goodsSearchInput'
  import { mapGetters } from 'vuex'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      TableInputWindow,
      GoodsSearch,
    },
    data() {
      var validatePass1 = (rule, value, callback) => {
        // console.log(rule, value, callback)
        if (value === '') {
          callback(new Error('请选择仓库'))
        } else if (value == this.form.out_id) {
          callback(new Error('不能选择同一个仓库'))
        } else {
          callback()
        }
      }
      var validatePass2 = (rule, value, callback) => {
        // console.log(rule, value, callback)
        if (value === '') {
          callback(new Error('请选择仓库'))
        } else if (value == this.form.in_id) {
          callback(new Error('不能选择同一个仓库'))
        } else {
          callback()
        }
      }
      return {
        // 表格合计
        allTotal: 0,
        edit: true,
        dialogVisible: false,
        cklist: [],
        yglist: [],
        type: [
          {
            id: 2,
            type_name: '仓库调拨',
          },
          {
            id: 3,
            type_name: '车存申请',
          },
          {
            id: 4,
            type_name: '车存退库',
          },
        ],
        totalNum: 0,
        form: {
          in_id: '',
          out_id: '',
          allot_type: '',
          add_id: '',
          goods: [],
          remark: '',
        },
        tabData: [],
        rules: {
          in_id: [
            {
              validator: validatePass1,
              trigger: 'change',
            },
          ],
          out_id: [
            {
              validator: validatePass2,
              trigger: 'change',
            },
          ],
        },
        url: {
          chaungjian: '/depotAdmin/bill-allot/add',
        },
        goodsTypeList: [
          {
            id: 1,
            name: '常品',
          },
          {
            id: 2,
            name: '赠品',
          },
        ],
        list: [
          {
            activity_code: '',
            activity_id: 0,
            activity_type: 0,
            balance_count: 0,
            depot_stock_num: 0,
            depot_stock_num_read: 0,
            edit: true,
            goods_child_id: '',
            goods_id: 0,
            goods_money: '',
            goods_name: '',
            goods_price: '',
            goods_production_date: '',
            goods_type: '',
            info: '',
            money: '',
            quantity: '',
            specs: '',
            unit_id: '',
          },
        ],
      }
    },
    computed: {
      goodsDisable() {
        if (this.form.in_id && this.form.out_id) {
          return false
        } else {
          return true
        }
      },
      ...mapGetters({
        avatar: 'user/avatar',
        username: 'user/username',
      }),
      showBtn() {
        let depotId = this.form.out_id
        let ckList = this.cklist
        if (depotId) {
          let ck = ckList.filter((i) => i.depot_type == 2)
          console.log(ck.findIndex((z) => z.depot_id == depotId))
          if (ck.findIndex((z) => z.id == depotId) > -1) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
      goodsEdit() {
        let a = this.form.allot_type > 0 ? false : true
        return a
      },
      // 类型是否可选
      typeEdit() {
        if (this.list.length > 1) {
          return true
        } else if (this.list.length == 1) {
          if (this.list[0].goods_id > 0) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
    },
    watch: {
      dialogVisible(val) {
        if (!val) {
          this.$emit('getlist')
          this.$refs['form'].resetFields()
          this.list = [
            {
              activity_code: '',
              activity_id: 0,
              activity_type: 0,
              balance_count: 0,
              depot_stock_num: 0,
              depot_stock_num_read: 0,
              edit: true,
              goods_child_id: '',
              goods_id: 0,
              goods_money: '',
              goods_name: '',
              goods_price: '',
              goods_production_date: '',
              goods_type: '',
              info: '',
              money: '',
              quantity: '',
              specs: '',
              unit_id: '',
            },
          ]
        }
      },
    },
    mounted() {
      this.$EventBus.$on('addshang', this.handlerData)
    },
    methods: {
      handlerselect(val, arr, row) {
        console.log(row, '选单位的回调')
        arr.forEach((list) => {
          if (val == list.unit_id) {
            // row.goods_price = list.sell_price
            // this.$set(row, 'goods_price', list.allot_scheme_price)
            if (!list.allot_scheme_price) {
              // row.goods_price = list.sell_price
              this.$set(row, 'goods_price', list.sell_price)
            } else {
              // row.goods_price = list.allot_scheme_price
              this.$set(row, 'goods_price', list.allot_scheme_price)
            }
            var nams = row.quantity
            this.$set(row, 'quantity', nams)
          }
        })
      },
      // 表格合计
      getSummaries({ data, param }) {
        let sums = []
        let sum = 0
        console.log(data)
        data.forEach((i) => {
          if (i.goods_price) {
            sum += i.goods_price * Number(i.quantity)
          }
        })
        sums[0] = '合计'
        sums[8] = sum.toFixed(2)
        return sums
      },
      //保存并审核
      handlerbaocun() {
        this.form.goods = []
        console.log(this.list, '')
        this.list.forEach((item) => {
          if (item.unit_id != '') {
            var a = {
              goodsId: item.goods_id, //产品ID
              goodsName: item.goods_name, //产品名称
              goodsNum: item.quantity, //数量
              goodsPrice: item.goods_price, //价格
              goodsSpec: item.specs, //规格
              goods_type: item.goods_type, //规格
              goodsUnit: item.unit_name, //单位
              unitID: item.unit_id, //单位ID
              goodsDate: item.goods_production_date, //生产日期
              goodsChildId: item.goods_child_id,
            }
            this.form.goods.push(a)
          }
        })
        // this.form.goods.splice(this.form.goods.length - 1, 1)
        var data = JSON.parse(JSON.stringify(this.form))
        data.goods = JSON.stringify(data.goods)
        this.$refs['form'].validate((valid) => {
          if (valid) {
            postAction(this.url.chaungjian, data)
              .then((res) => {
                console.log(res, '')
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
                this.dialogVisible = false
                this.$emit('getlist')
              })
              .catch((err) => {
                console.log(err, '')
              })
          } else {
            this.$message.error('请选择仓库和商品后提交')
            return false
          }
        })
      },
      handlerData(map) {
        console.log(map, 'map数据')
        this.cklist = map.get('cklist')
        // this.type = map.get('type')
        this.yglist = map.get('yglist')
        this.$EventBus.$off('addshang')
      },
      handleCurrentChange(val) {
        this.currentRow = val
      },
      //table
      copyRow(index, row) {
        let rowS = JSON.parse(JSON.stringify(row))
        let rowa = Object.assign(rowS, { id: row.id++, edit: false })
        this.list.splice(index, 0, rowa)
      },
      deleteRow(index, row) {
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一条数据')
          this.list = []
          this.addRow()
        } else {
          this.list.splice(index, 1)
        }
      },
      iconClick(index, row) {
        console.log(index, row)
        // this.tableInputWindow = true
        this.$refs['tableInputWindow'].tableInputWindow = true
        this.$refs['tableInputWindow'].depot_id = this.form.depot_id
      },
      // 计算金额数
      moneyN(item, index) {
        let num = 0
        if (Number(item.quantity) && Number(item.goods_price)) {
          num = Number(item.quantity) * Number(item.goods_price)
        }
        console.log('set')
        if (item.goods_type == 1) {
          this.$set(item, 'goods_money', num.toFixed(2))
          item.money = num.toFixed(2)
        } else {
          this.$set(item, 'goods_money', 0)
          item.money = 0
        }

        // this.getSummaries()
      },
      moneyNum(item) {
        let num = 0
        if (Number(item.quantity) && Number(item.goods_price)) {
          num = Number(item.quantity) * Number(item.goods_price)
        }
        return num.toFixed(2)
      },
      addRows(val, index) {
        console.log('addRows')
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            this.list.splice(this.list.length - 1, 0, item)
            let defaultUnit = item.arr_unit.filter(
              (i) => i.id == item.unit_id
            )[0]
            if (!defaultUnit.allot_scheme_price) {
              item.goods_price = defaultUnit.sell_price
            } else {
              item.goods_price = defaultUnit.allot_scheme_price
            }
          })
          this.$message.success('添加成功')
        }
        // val.forEach((item) => {
        //   item.goods_uint_change = item.unit_cv
        //   // item.goods_money = item.sum_money
        //   // item.goods_specs = item.specs
        //   // item.goods_num = item.quantity
        //   this.$set(item, 'goods_num', item.quantity)
        //   this.$set(item, 'goods_specs', item.specs)
        //   this.$set(item, 'goods_money', item.goods_money)
        //   this.$set(item, 'sum_money', 0)

        //   this.list.splice(index, 0, item)
        //   // 默认单位
        //   item.arr_unit.forEach((item2) => {
        //     if (item2.is_default == '2') {
        //       console.log(item2)
        //       item.unit_id = item2.id
        //       item.goods_code = item2.unit_code
        //     }
        //   })
        // })
      },
      handleSelect(index, row, $event) {
        if ($event.arr_unit.length != 0) {
          let g = JSON.parse(JSON.stringify(row.goods_type))
          Object.assign(row, $event)
          console.log(index, row, g)
          if (g) {
            console.log('asd')
            row.goods_type = g
          } else {
            row.goods_type = 1
          }
          let defaultUnit = row.arr_unit.filter((i) => i.id == row.unit_id)[0]
          if (!defaultUnit.allot_scheme_price) {
            row.goods_price = defaultUnit.sell_price
          } else {
            row.goods_price = defaultUnit.allot_scheme_price
          }
          console.log('defaultUnit', defaultUnit)
          // row.goods_price =
          // row = $event
          // row.arr_unit = $event.arr_unit
          // row.arr_goods_type = $event.arr_goods_type
          // row.goods_type = 1
          // row.goods_price = $event.arr_unit[0].allot_price
          // row.unit_name = $event.arr_unit[0].unit_name
          // row.unit_name = '0'
          // 默认单位
          // row.arr_unit.forEach((item) => {
          //   if (item.is_default == '2') {
          //     console.log(item)
          //     row.unit_id = item.id
          //     row.goods_code = item.unit_code
          //   }
          //   // if (item.purc_pirce == 0) {
          //   //   row.goods_price = item.cost_price
          //   // } else {
          //   //   row.goods_price = item.purc_pirce
          //   // }
          // })
          this.addRow()
        }
      },
      // 添加行
      addRow() {
        this.list.push({
          activity_code: '',
          activity_id: 0,
          activity_type: 0,
          balance_count: 0,
          depot_stock_num: 0,
          depot_stock_num_read: 0,
          edit: true,
          goods_child_id: null,
          goods_id: 0,
          goods_money: '',
          goods_name: '',
          goods_price: '',
          goods_production_date: '',
          goods_type: '',
          info: '',
          money: '',
          quantity: '',
          specs: '',
          unit_id: '',
        })
      },
      inputfocus(row) {
        console.log('input focus')
        if (row.quantity == 0) {
          row.quantity = ''
        }
      },
      // 商品搜索下拉
      async fetchData(ky = '') {
        let { data, code, msg } = await getInputList({
          pageNo: 1,
          pageSize: -1,
          keyword: ky,
          depot_id: this.form.out_id,
        })
        console.log(data)
        if (code == 200) {
          return data
        } else {
          this.$message.error(msg)
        }
      },
      // 表格内商品名称输入框搜索
      querySearch2(queryString, cb) {
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            console.log(val)
            cb(val)
          })
        }
      },
      // 勾选表格触发事件
      setSelectRows(val) {
        console.log(val)
        this.selectRows = val
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
      handleLoadCar() {
        // depot_type 2 che

        postAction('/baseAdmin/goods/window-view-do', {
          pageSize: -1,
          big_stock: 1,
          depot_id: this.form.out_id,
        }).then((r) => {
          let val = r.data
          console.log('val', val)

          if (val.length !== 0) {
            val.forEach((item) => {
              let haveed = this.list.filter(
                (goods) => goods.goods_id == item.goods_id
              )
              console.log('haveed', haveed)
              if (haveed.length !== 0) {
                // 存在相同
                console.log('存在相同')
              } else {
                item.goods_type = 1
                if (item.arr_unit.length > 0) {
                  let defaultUnitId = item.arr_unit.filter(
                    (i) => i.is_default == 2
                  )
                  if (defaultUnitId.length > 0) {
                    item.unit_id = defaultUnitId[0].id
                  } else {
                    item.unit_id = item.arr_unit[0].id
                  }
                }
                this.list.splice(this.list.length - 1, 0, item)
                let defaultUnit = item.arr_unit.filter(
                  (i) => i.id == item.unit_id
                )[0]
                if (!defaultUnit.allot_scheme_price) {
                  item.goods_price = defaultUnit.sell_price
                } else {
                  item.goods_price = defaultUnit.allot_scheme_price
                }
              }
            })
            this.$message.success('添加成功')
          }
        })
        // {
        //   activity_code: '',
        //   activity_id: 0,
        //   activity_type: 0,
        //   balance_count: '0',
        //   depot_stock_num: '0',
        //   depot_stock_num_read: '0',
        //   edit: true,
        //   goods_child_id: 0,
        //   goods_id: '1341',
        //   goods_money: 0,
        //   goods_name: '青花二十五年',
        //   goods_price: 100,
        //   goods_production_date: '',
        //   goods_type: 1,
        //   info: '',
        //   money: '',
        //   quantity: 0,
        //   specs: '1*6',
        //   unit_id: '1869',
        //   id: '1341',
        //   brand_id: '1311',
        //   unit_name: '',
        //   activity_count: 0,
        //   class_name: '白酒',
        //   brand_name: '汾酒',
        //   is_close_text: '正常',
        //   sale_type_text: '',
        //   is_img: '无',
        //   unit_cv: '1件=6瓶',
        //   balance_count_read: '0',
        //   depot_stock_num_text: '0',
        //   child: [],
        //   arr_unit: [
        //   ],
        //   arr_goods_type: [
        //   ],
        // }
      },
      changeKouwei(val, row) {
        console.log('改变口味', val)
        row.goods_child_id = val.id
        row.goods_name = val.goods_name + ` (${val.attr})`
      },
    },
  }
</script>
<style>
  .texts {
    display: flex;
    flex-direction: row;
    padding: 30px 0px;
  }
  .zindex {
    z-index: 3333;
  }
</style>
