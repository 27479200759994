<!--
 * @Author: 候怀烨
 * @Date: 2020-11-25 18:59:49
 * @LastEditTime: 2021-03-26 15:39:05
 * @LastEditors: Please set LastEditors
 * @Description: 调拨单
 * @FilePath: \sd-vue-admin\src\views\project\treasury\requisition\documents\index.vue
-->
<template>
  <!-- NAME[epic=库管]  调拨单-->
  <div class="contents">
    <el-form ref="form" :model="form" inline class="demo-form-inline">
      <el-form-item label="出货仓库：" prop="out_id">
        <el-select
          v-model="form.out_id"
          filterable
          placeholder="请选择"
          style="width: 130px"
        >
          <el-option
            v-for="item in cklist"
            :key="item.id"
            :label="item.depot_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="入货仓库：" prop="in_id">
        <el-select
          v-model="form.in_id"
          filterable
          placeholder="请选择"
          style="width: 130px"
        >
          <el-option
            v-for="item in cklist"
            :key="item.id"
            :label="item.depot_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="start_time">
        <el-date-picker
          v-model="form.start_time"
          type="date"
          placeholder="选择开始日期"
          style="width: 130px"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="至" prop="end_time">
        <el-date-picker
          v-model="form.end_time"
          type="date"
          placeholder="选择结束日期"
          style="width: 130px"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="query_time">
        <el-checkbox v-model="form.query_time" true-label="1" false-label="0">
          查询审核时间
        </el-checkbox>
      </el-form-item>
      <el-form-item prop="allot_code">
        <el-input v-model="form.allot_code" placeholder="请输入单号或客户名称">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-form-item>
      <el-form-item label="申请人：" prop="add_id">
        <el-select
          v-model="form.add_id"
          filterable
          placeholder="请选择"
          style="width: 130px"
        >
          <el-option
            v-for="item in yglist"
            :key="item.user_id"
            :label="item.user_name"
            :value="item.user_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审核人：" prop="check_id">
        <el-select
          v-model="form.check_id"
          filterable
          placeholder="请选择"
          style="width: 130px"
        >
          <el-option
            v-for="item in yglist"
            :key="item.user_id"
            :label="item.user_name"
            :value="item.user_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类型：" prop="allot_type">
        <el-select
          v-model="form.allot_type"
          filterable
          placeholder="请选择"
          style="width: 130px"
        >
          <el-option
            v-for="item in type"
            :key="item.id"
            :label="item.type_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="结算方式:" prop="is_wxpay">
        <el-select
          v-model="form.is_wxpay"
          filterable
          placeholder="结算方式"
          style="width: 130px"
        >
          <el-option label="现金" value="0"></el-option>
          <el-option label="在线支付" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态：" prop="allot_status">
        <el-select
          v-model="form.allot_status"
          filterable
          placeholder="请选择"
          style="width: 130px"
        >
          <el-option
            v-for="item in status"
            :key="item.id"
            :label="item.status_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="Submit">查询</el-button>
        <el-button type="warning" @click="detory">重置</el-button>
      </el-form-item>
      <el-form-item type="flex" class="row-bg" justify="end">
        <el-row type="flex" class="row-bg" justify="end">
          <el-button type="primary" @click="adddb">添 加</el-button>
          <el-button type="primary" @click="handleExport">导 出</el-button>
        </el-row>
      </el-form-item>
    </el-form>
    <add-docum ref="adddocum" @getlist="getlist"></add-docum>
    <el-table
      ref="multipleTable"
      stripe
      :data="tabledoc"
      border
      :height="tableHeight"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="30"></el-table-column>
      <el-table-column
        v-for="(list, index) in colmit"
        :key="index"
        :prop="list.prop"
        :label="list.label"
        width=""
        :align="list.align"
      >
        <template #default="{ row }">
          <div v-if="list.label == '调拨单号'">
            <span class="uderline" @click="handlerchakan(row)">
              {{ row[list.prop] }}
            </span>
            <div v-if="row.order_codes !== ''"></div>
            <span
              v-if="row.order_codes !== ''"
              style="color: #f60"
              @click="handlerchakanF60(row)"
            >
              {{ row.order_codes }}
            </span>
          </div>
          <div v-else>
            {{ row[list.prop] }}
          </div>
        </template>
      </el-table-column>
      <!-- XD 预订单     XS 销售 -->
      <el-table-column align="center" label="操作" fixed="right" width="230">
        <template slot-scope="scope">
          <!-- allot_status=0或1，且can_check=0，操作里显示编辑按钮 -->
          <el-button
            v-show="scope.row.allot_status == 0 || scope.row.allot_status == 1"
            type="text"
            size="small"
            @click="handleEdit(scope.row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="scope.row.allot_status == 1 && authbutton[4].is_check == 1"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
          >
            审核
          </el-button>

          <el-button
            v-show="scope.row.allot_status != 1"
            type="text"
            size="small"
            @click="handlerchakan(scope.row)"
          >
            查看
          </el-button>
          <el-button type="text" size="small" @click="handlePrint(scope.row)">
            打印
          </el-button>

          <el-popconfirm
            icon="el-icon-info"
            title="确定要作废吗？"
            @confirm="handleDelete(scope.$index, scope.row)"
          >
            <!-- <el-button slot="reference" type="text">删除</el-button> -->
            <el-button
              v-show="scope.row.allot_status == 2"
              slot="reference"
              type="text"
              style="margin-left: 10px"
            >
              <!-- @click="handleDelete(scope.$index, scope.row)" -->
              作废
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        :current-page="form.pageNo"
        :page-sizes="[10, 15, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <add-goods ref="shenhe" @getlist="getlist"></add-goods>
    <edit ref="edit" @getlist="getlist"></edit>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="DB"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <advanceOrder ref="advanceOrder"></advanceOrder>
  </div>
</template>

<script>
  import AddDocum from './components/AddDocum.vue'
  import AddGoods from './components/AddGoods.vue'
  import edit from './components/edit.vue'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { downloadFile, postAction } from '@/api/Employee'
  import advanceOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  import { day_n } from '@/utils/Time'
  import { getSelect } from '@/api/saleOrder'
  export default {
    components: {
      AddDocum,
      AddGoods,
      edit,
      RuilangDisplayDialog,
      advanceOrder,
    },
    data() {
      return {
        data_id: 0,
        form: {
          in_id: '', //出库id
          out_id: '', //入库id
          allot_code: '', //单号
          start_time: '', //开始时间
          end_time: '', //结束时间
          query_time: '', //查询时间类型 0申请时间 1审核时间 默认是0
          allot_type: '', //调拨类型
          allot_status: '', //调拨状态
          add_id: '', //申请人id
          check_id: '', //审核人ID
          pageSize: 10,
          pageNo: 1,
        },
        url: {
          eventData: '/depotAdmin/bill-allot/list',
          warehouse: '/baseAdmin/common/depot-list',
          Employee: '/baseAdmin/user/index',
          type: '/baseAdmin/common/allot-type',
          status: '/baseAdmin/common/allot-status',
          zuof: '/depotAdmin/bill-allot/scrap',
        },
        cklist: [],
        yglist: [],
        type: [],
        status: [],
        tabledoc: [],
        colmit: [
          {
            prop: 'allot_code',
            label: '调拨单号',
            width: '180',
            align: 'center',
          },
          {
            prop: 'cust_name',
            label: '客户名称',
            width: '',
            align: 'center',
          },
          {
            prop: 'create_at',
            label: '调拨时间',
            width: '180',
            align: 'center',
          },
          {
            prop: 'out_name',
            label: '出货仓库',
            width: '100',
            align: 'center',
          },
          {
            prop: 'in_name',
            label: '入货仓库',
            width: '100',
            align: 'center',
          },
          {
            prop: 'type_text',
            label: '类型',
            width: '80',
            align: 'center',
          },
          {
            prop: 'status_text',
            label: '状态',
            width: '80',
            align: 'center',
          },
          {
            prop: 'add_name',
            label: '申请人',
            width: '80',
            align: 'center',
          },
          {
            prop: 'check_name',
            label: '审核人',
            width: '80',
            align: 'center',
          },
          {
            prop: 'check_at',
            label: '审核时间',
            width: '180',
            align: 'center',
          },
          {
            prop: 'remark',
            label: '备注',
            width: '180',
            align: 'center',
          },
          {
            prop: 'print_num',
            label: '打印数',
            width: '60',
            align: 'center',
          },
        ],
        totalCount: 0,
        tableHeight: 600,
        authbutton: [],
      }
    },
    mounted() {
      getSelect().then((res) => {
        // this.areaList = res.data.cust_area //区域
        this.authbutton = res.data.fun_list
      })
      // 获取url参数
      this.initTime()
      console.log('获取url参数', this.$route.query.allot_status)
      if (this.$route.query.allot_status) {
        this.form.allot_status = Number(this.$route.query.allot_status)
        this.handlerData()
      } else {
        this.handlerData()
      }
      this.handlerck()
      this.handleryg()
      this.handlerlx()
      this.handlerzt()
      this.getHeight()

      //增加监听事件，窗口变化时得到高度。
      window.addEventListener('resize', this.getHeight, false)
    },
    methods: {
      initTime() {
        this.form.start_time = day_n(90)[0]
        this.form.end_time = day_n(90)[1]
        // console.log('day_n(90)', day_n(90))
      },
      getHeight() {
        //获取浏览器高度并计算得到表格所用高度。
        this.tableHeight = document.documentElement.clientHeight - 180
        console.log(document.documentElement.clientHeight)
      },
      handleDelete(index, row) {
        postAction(this.url.zuof, { allot_id: row.id })
          .then((res) => {
            console.log(res, '')
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'warning',
              })
              this.getlist()
            }
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleSelectionChange(val) {
        console.log(val, '')
      },
      handlerchakan(row) {
        this.$refs.shenhe.dialogVisible = true
        this.$refs.shenhe.type = 1
        this.$refs.shenhe.handlerlist(row)
      },
      // 查看预订单
      handlerchakanF60(row) {
        console.log('r', row)
        if (row.order_codes.indexOf('XD') == 0) {
          // 预订单查看
          this.$refs.advanceOrder.id = row.order_id
          this.$refs.advanceOrder.orderStatus2 = row.status_text
          this.$refs.advanceOrder.isshowDialog = true
        }
      },
      handleClick(row) {
        console.log(row, '')
        this.$refs.shenhe.cancel = this.authbutton[5].is_check
        this.$refs.shenhe.dialogVisible = true
        this.$refs.shenhe.type = 2
        this.$refs.shenhe.handlerlist(row)
      },
      getlist() {
        this.handlerData()
      },
      //haedr
      detory() {
        this.$refs['form'].resetFields()
        this.handlerData()
      },
      Submit() {
        this.form.pageNo = 1
        this.handlerData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.handlerData()
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.handlerData()
      },
      handlerData() {
        postAction(this.url.eventData, this.form)
          .then((res) => {
            console.log(res, '调拨单列表')
            this.tabledoc = res.data
            this.totalCount = res.totalCount
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlePrint(row) {
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      handlerck() {
        postAction(this.url.warehouse, {})
          .then((res) => {
            console.log(res, '仓库')
            this.cklist = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleryg() {
        postAction(this.url.Employee, { pageSize: -1 })
          .then((res) => {
            console.log(res, '员工')
            this.yglist = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerlx() {
        postAction(this.url.type, {})
          .then((res) => {
            console.log(res, '类型')
            this.type = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerzt() {
        postAction(this.url.status, {})
          .then((res) => {
            console.log(res, '状态')
            this.status = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      adddb() {
        var map = new Map()
        map.set('cklist', this.cklist)
        map.set('yglist', this.yglist)
        map.set('type', this.type)
        console.log(map, '')
        this.$EventBus.$emit('addshang', map)
        // console.log(this.$refs.adddocum.dialogVisible, '')
        this.$refs.adddocum.dialogVisible = true
        // console.log(this.$refs.adddocum.dialogVisible, '')
      },
      handleExport() {
        downloadFile(
          '/depotAdmin/bill-allot/list-export',
          '调拨单.xlsx',
          this.form
        )
      },
      handleEdit(row) {
        console.log(row)
        console.log(row, '')
        this.$refs.edit.dialogVisible = true
        this.$refs.edit.type = 2
        this.$refs.edit.handlerlist(row)
      },
    },
  }
</script>

<style>
  .contents {
    padding: 20px;
  }
</style>
