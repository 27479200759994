var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "添加调拨单",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            center: "",
            width: "1100px",
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form-inline",
                  attrs: { model: _vm.form, inline: true, rules: _vm.rules },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "出货仓库：", prop: "out_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "130px" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.form.out_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "out_id", $$v)
                            },
                            expression: "form.out_id",
                          },
                        },
                        _vm._l(_vm.cklist, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.depot_name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "入货仓库：", prop: "in_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "130px" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.form.in_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "in_id", $$v)
                            },
                            expression: "form.in_id",
                          },
                        },
                        _vm._l(_vm.cklist, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.depot_name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "" } },
                    [
                      _vm.showBtn
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleLoadCar },
                            },
                            [_vm._v(" 加载出货库车存 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "", prop: "" } }, [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        " 提示:如使用调拨价方案，添加、编辑调拨单时请不要修改车库 "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  ref: "tableSort",
                  attrs: {
                    stripe: "",
                    data: _vm.list,
                    border: "",
                    "show-summary": "",
                    "summary-method": _vm.getSummaries,
                  },
                  on: { "selection-change": _vm.setSelectRows },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", type: "selection", width: "90" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "序号",
                      "min-width": "50px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          return [_c("span", [_vm._v(_vm._s($index + 1))])]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "类型",
                      width: "110",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "类型" },
                                model: {
                                  value: row.goods_type,
                                  callback: function ($$v) {
                                    _vm.$set(row, "goods_type", $$v)
                                  },
                                  expression: "row.goods_type",
                                },
                              },
                              _vm._l(_vm.goodsTypeList, function (i, idx) {
                                return _c("el-option", {
                                  key: idx,
                                  attrs: { value: i.id, label: i.name },
                                })
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goods_name",
                      align: "center",
                      label: "商品名称",
                      "min-width": "150px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _vm.edit
                              ? [
                                  _c("goods-search", {
                                    ref: "goodsSearch",
                                    attrs: {
                                      "f-key": row.goods_name,
                                      "depot-id": _vm.form.out_id,
                                      dbj: true,
                                      disab: _vm.goodsDisable,
                                      "goods-list-obj": {
                                        allot_type: _vm.form.allot_type,
                                        depot_id2: _vm.form.in_id,
                                      },
                                      kouwei: row.child,
                                      rowf: row,
                                    },
                                    on: {
                                      "add-rows": _vm.addRows,
                                      "select-goods-all": function ($event) {
                                        return _vm.handleSelect(
                                          $index,
                                          row,
                                          $event
                                        )
                                      },
                                      "change-kouwei": function ($event) {
                                        return _vm.changeKouwei($event, row)
                                      },
                                    },
                                  }),
                                ]
                              : _c("span", [_vm._v(_vm._s(row.goods_name))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "type",
                      align: "center",
                      label: "单位",
                      width: "140px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm.edit
                              ? [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "110px" },
                                      attrs: { placeholder: "选择单位" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handlerselect(
                                            row.unit_id,
                                            row.arr_unit,
                                            row
                                          )
                                        },
                                      },
                                      model: {
                                        value: row.unit_id,
                                        callback: function ($$v) {
                                          _vm.$set(row, "unit_id", $$v)
                                        },
                                        expression: "row.unit_id",
                                      },
                                    },
                                    _vm._l(row.arr_unit, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.unit_name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ]
                              : _c("span", [_vm._v(_vm._s(row.units))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "specs", align: "center", label: "规格" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "quantity",
                      align: "center",
                      label: "调拔数量",
                      "min-width": "110px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _vm.edit
                              ? _c(
                                  "span",
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80px" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.moneyN(row, $index)
                                        },
                                        focus: function ($event) {
                                          return _vm.inputfocus(row)
                                        },
                                      },
                                      model: {
                                        value: row.quantity,
                                        callback: function ($$v) {
                                          _vm.$set(row, "quantity", $$v)
                                        },
                                        expression: "row.quantity",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("span", [_vm._v(_vm._s(row.quantity))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goods_price",
                      align: "center",
                      label: "单价",
                      "min-width": "110px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _vm.edit
                              ? [
                                  _c("el-input", {
                                    staticStyle: { width: "80px" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.moneyN(row, $index)
                                      },
                                    },
                                    model: {
                                      value: row.goods_price,
                                      callback: function ($$v) {
                                        _vm.$set(row, "goods_price", $$v)
                                      },
                                      expression: "row.goods_price",
                                    },
                                  }),
                                ]
                              : _c("span", [_vm._v(_vm._s(row.goods_price))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goods_money",
                      align: "center",
                      label: "金额",
                      "min-width": "90px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.goods_type == 1
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          row.goods_price * Number(row.quantity)
                                        ).toFixed(2)
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", [_vm._v("0")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "depot_stock_num_read",
                      align: "center",
                      label: "可用库存",
                      "min-width": "110px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " + _vm._s(row.depot_stock_num_read) + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "balance_count",
                      align: "center",
                      label: "实际库存",
                      "min-width": "110px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(" " + _vm._s(row.balance_count_read) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      width: "160",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.copyRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "texts" },
                [
                  _c("span", [_vm._v("备注：")]),
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c("span", [_vm._v("制单人：" + _vm._s(_vm.username))]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerbaocun },
                },
                [_vm._v("添加")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("table-input-window", { ref: "tableInputWindow" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }