<template>
  <div>
    <el-dialog
      v-loading.fullscreen.lock="fullscreenLoading"
      :modal="false"
      :close-on-click-modal="false"
      :title="'调拨单号：' + form.bill_no"
      :visible.sync="dialogVisible"
      width="1100px"
      center
    >
      <div class="rods">
        <el-form
          ref="form"
          v-model="form"
          :inline="true"
          class="demo-form-inline"
          label-width="150px"
        >
          <el-form-item label="出货仓库：">
            <el-select
              v-model="form.out_id"
              :disabled="type == 1"
              placeholder="出货仓库"
              style="width: 130px"
            >
              <el-option
                v-for="list in cklist"
                :key="list.id"
                :label="list.depot_name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请时间：">
            <span>{{ form.add_time }}</span>
          </el-form-item>
          <el-form-item label="申请人：">
            <span>{{ form.add_name }}</span>
          </el-form-item>
          <el-form-item v-if="form.allot_status != 1" label="审核时间：">
            <span>{{ form.check_time }}</span>
          </el-form-item>
          <el-form-item label="入货仓库：">
            <el-select
              v-model="form.in_id"
              :disabled="type == 1"
              placeholder="入货仓库"
              style="width: 130px"
            >
              <el-option
                v-for="item in cklist"
                :key="item.id"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="调拨类型：">
            <span>{{ form.allot_type_text }}</span>
          </el-form-item>
          <el-form-item label="审核人：">
            <span>{{ form.check_name }}</span>
          </el-form-item>
        </el-form>
        <!-- show-summary -->
        <el-table
          stripe
          :data="form.goods_data"
          border
          style="width: 100%"
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            width="50"
            align="center"
          ></el-table-column>
          <!-- 商品类型 -->
          <el-table-column
            label="类型"
            width="60"
            prop="goods_type"
            align="center"
          >
            <template #default="{ row }">
              {{
                row.goods_type == 1 ? '常品' : row.goods_type == 2 ? '赠品' : ''
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="商品名称"
            align="center"
            prop="goods_name_print"
            width="260"
          >
            <template #default="{ row }">
              <goods-search
                :f-key="row.goods_name"
                :goods-list-obj="{
                  depot_id: form.out_id,
                  depot_id2: form.in_id,
                }"
                @add-rows="addRows"
                @select-goods-all="handleSelect($event, row)"
              ></goods-search>
            </template>
          </el-table-column>
          <el-table-column
            label="商品规格"
            align="center"
            prop="goods_spec"
            width="150"
          ></el-table-column>
          <el-table-column
            label="单位"
            align="center"
            prop="unit_id"
            width="110"
          >
            <template #default="{ row }">
              <el-select
                v-model="row.unit_id"
                placeholder="选择单位"
                @change="unitChange($event, row)"
              >
                <el-option
                  v-for="item in row.arr_unit"
                  :key="item.id"
                  :label="item.unit_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="申请数量"
            align="center"
            prop="apply_goods_num"
            width="80"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.apply_goods_num"></el-input>
            </template>
          </el-table-column> -->
          <el-table-column
            label="申请数量"
            align="center"
            prop="goods_num"
            width="100"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.goods_num"
                @input="moneyN(scope.row)"
                @focus="inputfocus(scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="单价"
            align="center"
            prop="goods_price"
            width="100"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.goods_price"
                :disabled="form.allot_status != 1"
                @input="moneyN(scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="金额"
            align="center"
            prop="total_price"
            width="80"
          ></el-table-column>
          <el-table-column label="操作" align="center" prop="" width="120">
            <template #default="{ $index }">
              <el-button type="text" @click="handleAdd($index)">添加</el-button>
              <el-button type="text" @click="handleDelete($index)">
                删除
              </el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="备注"
            align="center"
            prop="remark"
            width="150"
          ></el-table-column> -->
        </el-table>
        <el-row class="beizhu">
          <span>备注：</span>
          <el-input
            v-model="form.remark"
            type="textarea"
            :rows="2"
            placeholder="备注"
            style="width: 220px"
          ></el-input>
        </el-row>
        <div v-if="form.old_code">
          <span>修改记录:</span>
          <el-table
            stripe
            :data="changeOld"
            border
            style="width: 50%"
            height="100"
          >
            <el-table-column label="原单号" align="center" prop="old_code">
              <template #default="{ row }">
                <span class="underline" @click="handleCheckDiaoboOrder(row)">
                  {{ row.old_code }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="修改时间"
              align="center"
              prop="add_time"
            ></el-table-column>
            <el-table-column
              label="修改人"
              align="center"
              prop="update_name"
            ></el-table-column>
          </el-table>
        </div>

        <div>
          <span>相关订单:</span>
          <el-table
            stripe
            :data="form.orders"
            border
            style="width: 50%"
            height="200"
          >
            <el-table-column
              v-for="trim in ordersd"
              :key="trim.order_id"
              :label="trim.label"
              :align="trim.center"
              :prop="trim.prop"
              :width="trim.width"
            >
              <template #default="{ row }">
                <div v-if="trim.label == '单号'">
                  <span class="underline" @click="handleCheckOrder(row)">
                    {{ row[trim.prop] }}
                  </span>
                </div>
                <div v-else>
                  {{ row[trim.prop] }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="flexe">
          <span>{{ form.allot_status_text }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="isEdit && !showOld" type="primary" @click="shenhe">
          修改
        </el-button>

        <el-button
          v-if="isEdit"
          v-show="form.allot_status == 1 && !showOld"
          @click="zuofei"
        >
          作 废
        </el-button>
        <el-button v-if="showOld" type="primary" @click="handleBack">
          返回
        </el-button>
        <el-button @click="handlePrint">打 印</el-button>
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="DB"
      :data_id="form.id"
      :type="1"
    ></ruilang-display-dialog>
    <advance-order ref="advanceOrder"></advance-order>
    <sale-order ref="saleOrder"></sale-order>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import advanceOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  import saleOrder from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    components: {
      RuilangDisplayDialog,
      advanceOrder,
      saleOrder,
      GoodsSearch,
    },
    props: {
      isEdit: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        changeOld: [],
        dialogVisible: false,
        type: 2, //1查看2审核
        cancel: 0,
        form: {
          bill_no: '',
          out_id: '',
          out_name: '',
          in_id: '',
          id_name: '',
          add_time: '',
          check_time: '',
          allot_type: '',
          allot_type_text: '',
          allot_status_text: '',
          remark: '',
          add_name: '',
          check_name: '',
        },
        showOld: false,
        id: '',
        cklist: [],
        url: {
          lists: '/depotAdmin/bill-allot/info',
          warehouse: '/baseAdmin/common/depot-list',
          fei: '/depotAdmin/bill-allot/cancel',
          shenh: '/depotAdmin/bill-allot/update',
        },
        fullscreenLoading: false,
        ordersd: [
          {
            label: '单号',
            align: 'center',
            prop: 'bill_code',
            width: '130',
          },
          {
            label: '客户',
            align: 'center',
            prop: 'cust_name',
            width: '80',
          },
          {
            label: '老板',
            align: 'center',
            prop: 'boss',
            width: '80',
          },
          {
            label: '电话',
            align: 'center',
            prop: 'mobile',
            width: '130',
          },
        ],
      }
    },
    watch: {
      dialogVisible(val) {
        if (!val) {
          this.$emit('getlist')
          this.changeOld = []
          this.showOld = false
        }
      },
    },
    mounted() {
      this.handlerck()
    },
    methods: {
      // 计算金额数
      moneyN(item) {
        let num = 0
        if (Number(item.goods_num) && Number(item.goods_price)) {
          num = Number(item.goods_num) * Number(item.goods_price)
        }
        if (item.goods_type == 1) {
          console.log('set', num)
          this.$set(item, 'total_price', num.toFixed(2))
          item.total_price = num.toFixed(2)
        } else {
          this.$set(item, 'total_price', 0)
          item.total_price = 0
        }
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        let name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        let id = row.arr_unit.filter((item) => item.id == e)[0].unit_id
        row.goods_price = price
        row.unit_name = name
        row.unit_id = id
      },
      getSummaries(param) {
        console.log('asd')
        // const { columns, data } = param
        // const sums = []
        // columns.forEach((column, index) => {
        //   if (index === 0) {
        //     sums[index] = '总价'
        //     return
        //   } else if (index == 3 || index == 4 || index == 5) {
        //     const values = data.map((item) => Number(item[column.property]))
        //     if (!values.every((value) => isNaN(value))) {
        //       sums[index] = values.reduce((prev, curr) => {
        //         const value = Number(curr)
        //         if (!isNaN(value)) {
        //           return prev + curr
        //         } else {
        //           return prev
        //         }
        //       }, 0)
        //       sums[index]
        //     } else {
        //       sums[index] = ''
        //     }
        //   }
        // })
        // return sums
        const { columns, data } = param
        // 需要合计的下标
        let i = [7]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]) || 0)
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  console.log('total, num', total, num)
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                console.log('a', a)
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      shenhe() {
        var goods = []
        this.form.goods_data.forEach((item) => {
          if (item.goods_id) {
            var b = {
              id: item.id,
              goodsId: item.goods_id,
              goodsName: item.goods_name,
              goodsNum: item.goods_num,
              goodsPrice: item.goods_price,
              goodsSpec: item.goods_spec,
              goodsUnit: item.goods_unit,
              unitID: item.unit_id,
              goodsChildId: item.goods_child_id,
              goodsDate: '',
            }
            console.log('Object.assign(b, item)', Object.assign(b, item))
            goods.push(Object.assign(b, item))
          }
        })
        postAction(this.url.shenh, {
          allot_id: this.form.id,
          out_id: this.form.out_id,
          in_id: this.form.in_id,
          goods: JSON.stringify(goods),
          remark: this.form.remark,
        })
          .then((res) => {
            console.log(res, '修改成功')
            if (res.code == 200) {
              setTimeout(() => {
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
                this.dialogVisible = false
                this.$emit('getlist')
              }, 800)
            }
          })
          .catch((err) => {
            console.log(err, '审核失败')
          })
      },
      zuofei() {
        postAction(this.url.fei, {
          allot_id: this.form.id,
        })
          .then((res) => {
            console.log(res, '作废成功')
            if (res.code == 200) {
              this.$message({
                message: '作废成功',
                type: 'warning',
              })
              this.dialogVisible = false
              this.$emit('getlist')
            }
          })
          .catch((err) => {})
      },
      handlerck() {
        this.fullscreenLoading = true
        postAction(this.url.warehouse, {})
          .then((res) => {
            console.log(res, '仓库')
            this.cklist = res.data
            this.fullscreenLoading = false
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      indexMethod(index) {
        return index + 1
      },

      handlerlist(row) {
        console.log(row, '详情')
        postAction(this.url.lists, { allot_id: row.id })
          .then((res) => {
            console.log(res, '详情成功')
            this.changeOld = []
            this.form = res.data
            if (!this.showOld) this.id = res.data.id
            this.changeOld.push({
              old_code: this.form.old_code,
              add_time: this.form.add_time,
              add_name: this.form.add_name,
              old_id: this.form.old_id,
              update_name: this.form.update_name,
            })
            if (this.form.allot_status == 1 || this.form.allot_status == 0) {
              this.form.goods_data.forEach((list) => {
                if (list.goods_num == 0) {
                  list.goods_num = JSON.parse(
                    JSON.stringify(list.apply_goods_num)
                  )
                }
              })
            }
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
      handlePrint() {
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      // 查看订单详情
      handleCheckOrder(row) {
        console.log(row)
        if (row.bill_code.indexOf('XS') > -1) {
          // 销售单查看
          this.$refs['saleOrder'].isshowDialog = true
          this.$refs['saleOrder'].id = Number(row.order_id)
        } else {
          // 预订单查看
          this.$refs['advanceOrder'].showDialog()
          this.$refs['advanceOrder'].orderStatus2 = row.bill_status_text
          this.$refs['advanceOrder'].id = Number(row.order_id)
        }
      },
      handleAdd($index) {
        this.form.goods_data.push({})
      },
      inputfocus(row) {
        console.log('input focus')
        if (row.goods_num == 0) {
          row.goods_num = ''
        }
      },
      handleDelete(index) {
        this.form.goods_data.splice(index, 1)
      },
      addRows(val) {
        console.log('添加rows', val)
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            var a = {
              goods_type: 1,
              apply_goods_num: 0,
              goods_num: 0,
              arr_unit: item.arr_unit,
              goods_id: item.goods_id,
              goods_name: item.goods_name,
              goods_spec: item.specs,
              unit_id: item.arr_unit[0].unit_id,
              unit_name: item.arr_unit[0].unit_name,
              goods_price: item.goods_price,
            }
            let dU = item.arr_unit.filter((i) => i.is_default == 2)[0]
            console.log('du', dU.unit_name)
            a.unit_id = dU.unit_id
            a.unit_name = dU.unit_name
            this.form.goods_data.splice(this.form.goods_data.length - 1, 0, a)
          })
          this.$message.success('添加成功')
        }
      },
      handleSelect(event, row) {
        console.log(event)
        // var data = {
        //   goods_type: 1,
        //   arr_unit: event.arr_unit,
        //   goods_id: event.goods_id,
        //   goods_name: event.goods_name,
        //   goods_spec: event.specs,
        //   unit_id: event.unit_id,
        //   unit_name: event.unit_name,
        //   goods_price: event.goods_price,
        //   sell_price: event.goods_price,
        // }
        // Object.assign(row, data)

        this.$set(row, 'goods_type', 1)
        this.$set(row, 'apply_goods_num', 0)
        this.$set(row, 'goods_num', 0)
        this.$set(row, 'arr_unit', event.arr_unit)
        this.$set(row, 'goods_id', event.goods_id)
        this.$set(row, 'goods_name', event.goods_name)
        this.$set(row, 'goods_spec', event.specs)
        this.$set(row, 'unit_id', event.unit_id)
        this.$set(row, 'unit_name', event.unit_name)
        this.$set(row, 'goods_price', event.goods_price)
        this.$set(row, 'sell_price', event.goods_price)
        // Object.assign(row, event)
      },
      handleCheckDiaoboOrder(row) {
        console.log(row)
        row.id = row.old_id
        this.handlerlist(row)
        this.showOld = !this.showOld
        // this.$refs.diaoboOrder.dialogVisible = true
        // this.$refs.diaoboOrder.type = 1
        // this.$refs.diaoboOrder.handlerlist(row)
      },
      handleBack() {
        let row = { id: this.id }
        this.showOld = !this.showOld
        this.handlerlist(row)
      },
    },
  }
</script>

<style>
  .beizhu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px;
  }
  .flexe {
    position: absolute;
    top: 0;
    right: 10%;
    padding: 30px 20px;
    font-weight: 600;
    color: chocolate;
    background-color: rgba(236, 225, 212);
    border: 1px chocolate solid;
    border-radius: 50%;
    opacity: 0.5;
  }
  .rods {
    position: relative;
  }
</style>
